/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        jQuery( document ).ready(function( $ ) {
 
          jQuery( '.faq__question' ).click(function(event) {
            event.preventDefault();

            if(jQuery( this ).hasClass('active')){
              jQuery( this ).removeClass( 'active' );
              jQuery( this ).find('.faq__question-arrow').removeClass('arrow-up');
              jQuery( this ).find('.faq__question-arrow').addClass('arrow-down');
              jQuery( this ).next( '.faq__answer' ).slideUp();
            } else {
              jQuery( this ).addClass( 'active' );
              jQuery( this ).find('.faq__question-arrow').removeClass('arrow-down');
              jQuery( this ).find('.faq__question-arrow').addClass('arrow-up');
              jQuery( this ).next( '.faq__answer' ).slideDown();
            }
          });  

          $(".country_picker").click(function(event) {
              $('.country-modal').show();
          });

          $(".modal__close").click(function(event) {
              $('.country-modal').hide();
          });

          $(document).click(function(event) {
            //if you click on anything except the modal itself or the "open modal" link, close the modal
            if (!$(event.target).closest(".modal__content, .country_picker").length) {
              $(".country-modal").hide();
            }
          });

          $(".dashboard-nav").click(function(event) {
              event.preventDefault();
              $('.dashboard-menu').toggle('slow');
          });

          /* Mobile menu */
          $(".parent").append('<i class="arrow-down mobile__parent"></i>');

          $(".mobile__parent").click(function(){
            if($(this).hasClass('arrow-down')){
              $(this).removeClass('arrow-down');
              $(this).addClass('arrow-up');
            } else {
              $(this).removeClass('arrow-up');
              $(this).addClass('arrow-down');
            }
            $(this).prev("ul").slideToggle('slow');
          });

          $(".active > .mobile__parent").removeClass('arrow-down').addClass('arrow-up');
        });                   
      }
    },
    // Checkout
    'checkout': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $(".woocommerce__checkout-information-link").click(function(event) {
          event.preventDefault();
          $('.woocommerce__checkout-information__content').toggle();
        });        
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

jQuery( document ).ready(function( $ ) {
    $("a.showcoupon").parent().hide();

    /* Use jQuery UI's dialog feature to load the coupon html code                                                  
     * into the anchor div. The width controls the width of the                                                     
     * modal dialog window. Check here for all the dialog options:                                                         
     * http://api.jqueryui.com/dialog/ */
    var dialog = $("form.checkout_coupon").dialog({                                                      
       autoOpen: false,                                                                             
       width: 500,                                                                                  
       minHeight: 0,                                                                                
       modal: false,                                                                                
       appendTo: "#coupon-anchor",                                                                  
       position: { my: "left", at: "left", of: "#coupon-anchor"},                                   
       draggable: false,                                                                            
       resizable: false,                                                                            
       dialogClass: "coupon-special",                                                               
       closeText: "Close",                                                                          
       buttons: {}});

    /* Bind the Click here to enter coupon link to load the                                                         
     * jQuery dialog with the coupon code. Note that this                                                               
     * implementation is a toggle. Click on the link again                                                          
     * and the coupon field will be hidden. This works in                                                           
     * conjunction with the hidden close button in the                                                               
     * optional CSS in style.css shown below. */
    $("#show-coupon-form").click( function() {                                                       
     if (dialog.dialog("isOpen")) {                                                               
         $(".checkout_coupon").hide();                                                            
         dialog.dialog( "close" );                                                                
     } else {                                                                                     
         $(".checkout_coupon").show();                                                            
         dialog.dialog( "open" );                                                                 
     }                                                                                            
     return false;});
  });